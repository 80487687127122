import React from 'react'
import mixpanel from 'mixpanel-browser';

import { GrandHeading } from '../../styles/typography';

import {
  ThirdScreen,
  ThirdScreenFooter,
  SubHeading,
  BulletPoint,
  BulletDescription,
  Link,
  BulletsContainer,
  Bullet,
  BulletDot,
  DownloadButton
} from './styled';

import Flex from '../../components/Flex';

export default ({ showModal }) => (
  <ThirdScreen>
    <GrandHeading>
      End User License Agreement
    </GrandHeading>
    <div style={{ margin: '20px'}}>
      <div style={{ marginBottom: '20px' }}>
        This agreement governs your trial and continues use of apps provided by
        WebPal vendor on the Atlassian JIRA Marketplace and Atlassian Trello Marketplace.
        Such app hereafter is referred as the Chronos Atlassian Marketplace Product.
      </div>

      <div style={{ marginBottom: '20px' }}>
        By installing or using the Chronos Atlassian Marketplace Product, you are agreeing to become bound by the terms of this agreement.
      </div>

      <div style={{ marginBottom: '20px' }}>
        If you are agreeing to this agreement not as an individual, but on behalf of your company, then “you” means your company, and you are binding your company to this agreement.
      </div>

      <div style={{ marginBottom: '20px' }}>
        By accepting this agreement you are granted a limited, worldwide, non-exclusive,
        non-transferable and non-sublicensable license to install and use
        the Chronos Atlassian Marketplace Product in corresponding Atlassian Product.
      </div>

      <div style={{ marginBottom: '20px' }}>
        Licenses granted by the WebPal vendor are granted subject to the condition that you must
        ensure the maximum number of Authorized Users that are able to access and use
        the Chronos Atlassian Marketplace Product concurrently is equal to the number of
        User Licenses for which the necessary fees have been paid to Atlassian and/or its
        authorized partners (each, an "Atlassian Expert"). You may purchase additional
        User Licenses at any time on payment of the appropriate fees to Atlassian or an Atlassian Expert.
        "User License" means a license granted under this Agreement to you to permit an Authorized User
        to use the Chronos Atlassian Marketplace Product. The number of User Licenses granted to
        you is dependent on the fees paid by you. "Authorized User" means a person who accesses and uses
        the Chronos Atlassian Marketplace Product under the Agreement and for which the
        necessary fees have been paid to Atlassian and/or an Atlassian Expert.
      </div>

      <div style={{ marginBottom: '20px' }}>
        Any information that Publisher collects from you or your device will be subject to any Publisher EULA,
        privacy notice, or similar terms that the Publisher provides to you, and will not be subject to the Atlassian Privacy Policy (unless Atlassian is the Publisher).
      </div>

      <div style={{ marginBottom: '20px' }}>
        You may not modify, reverse engineer, decompile or disassemble
        the Chronos Atlassian Marketplace Product in whole or in part, or create any derivative works
        from or sublicense any rights in the Chronos Atlassian Marketplace Product,
        unless otherwise expressly authorized in writing by WebPal vendor.
      </div>

      <div style={{ marginBottom: '20px' }}>
        Chronos Atlassian Marketplace Product is protected by copyright and other intellectual property laws and treaties.
        Unless otherwise expressly stated in this agreement, WebPal vendor owns all title, copyright and
        other intellectual property rights in the Chronos Marketplace Product, and
        the Chronos Atlassian Marketplace Product is licensed to you directly by the Prime Timesheet vendor, not sold.
      </div>

      <div style={{ marginBottom: '20px' }}>
        This agreement was last updated 10 February 2019.
      </div>
    </div>
  </ThirdScreen>
)
