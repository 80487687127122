import styled from 'styled-components';
import AtlassianWaveFooterSVG from '../../../../assets/atlassian-wave-footer.svg';
import { Link as ScrollLink } from 'react-scroll';

import {
  Heading as HeadingDefault,
  SubHeading as SubHeadingDefault,
} from '../../../styles/typography';

export const ThirdScreen = styled.div`
  width: 100vw;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 60px 0 0 0;
  background: #FFFFFF;
  box-shadow: inset 0 10px 14px 0 rgba(0,0,0,0.10);
  position: relative;
  @media (max-width: 768px) {
    height: auto;
    padding: 40px 16px 0 16px;
  }
`;

export const ThirdScreenFooter = styled(AtlassianWaveFooterSVG)`
  margin-top: 20px;
  width: 100vw;
  height: auto;
  g {
    fill: #172B4D;
  }
`;

export const Heading = styled(HeadingDefault)`
  color: #253858;
  text-align: center;
  @media (max-width: 768px) {
    font-size: 1.4rem;
    text-align: center;
  }
`;

export const SubHeading = styled(SubHeadingDefault)`
  text-align: center;
  color: #42526E;
  margin-bottom: 0px;
  @media (max-width: 768px) {
    font-size: 1.2rem;
    text-align: center;
  }
`;

export const Divider = styled.div`
  background: #FFAB00;
  width: 200px;
  height: 10px;
  margin: 48px 0;
`;

export const BulletPoint = styled.h3`
  font-family: Circular-Bold;
  font-size: 20px;
  color: #172B4D;
  margin-bottom: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const BulletDescription = styled.p`
  font-family: Circular-Book;
  font-size: 20px;
  color: #42526E;
  margin-left: 14px;
  @media (min-width: 768px) and (max-width: 940px) {
    br {
      display: none;
    }
  }
  @media (max-width: 768px) {
    margin-left: calc(6px + 5vw);
    br {
      display: none;
    }
  }
`;

export const Link = styled(ScrollLink)`
  font-family: Circular-Book;
  font-size: 20px;
  color: #0052CC;
  cursor: pointer;
  text-decoration: none;
  &:hover {
    color: #0065ff;
    text-decoration: underline;
  }
  :active {
    color: #0747a6;
  }
`;

export const BulletsContainer = styled.div`
  width: 100%;
  max-width: 70vw;
  @media (min-width: 768px) and (max-width: 1100px) {
    max-width: 84vw;
  }
  @media (max-width: 768px) {
    max-width: 90vw;
  }
`;

export const Bullet = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  display: inline-block;
  vertical-align: top;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const BulletDot = styled.div`
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #172B4D;
  margin-right: 8px;
  @media (max-width: 768px) {
    margin-right: 5vw;
  }
`;

export const DownloadButton = styled.button`
  background: #0052CC;
  border-radius: 3px;
  font-family: Circular-Bold;
  font-size: 20px;
  margin: 40px 0;
  color: #FFFFFF;
  padding: 20px;
  border: 0;
  width: fit-content;
  cursor: pointer;
  &:hover, :active {
    border-color: #004BBD;
    background: #004BBD;
    color: #FFF;
    box-shadow: 0 2px 4px rgba(0,0,0,.25);
  }
`;

