import React, { Component } from 'react'
import mixpanel from 'mixpanel-browser';
import Layout from '../components/Layout/Layout';

import Footer from '../components/Footer/Footer';
import TermsOfUse from '../components/TermsOfUse/TermsOfUse';

class Eula extends Component {
  componentDidMount() {
    mixpanel.init('5aba46554a0e8baceca1eee3947bd1ac');
  }

  render() {
    return (
      <Layout>
        <TermsOfUse />
        <Footer showModal={false} />
      </Layout>
    );
  }
}

export default Eula;
